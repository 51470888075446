var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"610px","scrollable":"","content-class":"content-scroll"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{staticClass:"ma-0 d-flex justify-space-between"},[_c('span',{staticClass:"font-weight-bold primary--text",class:{'text-h4': _vm.$vuetify.breakpoint.smAndUp, 'text-h5': _vm.$vuetify.breakpoint.xsOnly,}},[_vm._v(" Gestión de Grupo ")]),_c('v-btn',{attrs:{"icon":"","plain":""},on:{"click":function($event){return _vm.cerrar()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-overlay',{attrs:{"value":_vm.loadingAction || _vm.loading,"color":"white","absolute":"","opacity":".8"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}})],1),_c('v-card-text',[_c('validation-observer',{ref:"GROUP_FORM",attrs:{"tag":"div"}},[_c('v-row',[_c('v-col',{staticClass:"pt-2 pb-0",attrs:{"cols":"12","sm":"7","md":"8","lg":"8"}},[_c('label-form',{attrs:{"text":"Nombre","required":""}}),_c('validation-provider',{attrs:{"name":"Nombre","vid":"groupInfo.nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.data.nombre,"outlined":"","dense":"","error-messages":errors[0]},model:{value:(_vm.groupInfo.nombre),callback:function ($$v) {_vm.$set(_vm.groupInfo, "nombre", $$v)},expression:"groupInfo.nombre"}})]}}])})],1),_c('v-col',{staticClass:"pt-2 pb-0",attrs:{"cols":"12","sm":"5","md":"4","lg":"4"}},[_c('label-form',{attrs:{"text":"Código","required":""}}),_c('validation-provider',{attrs:{"name":"codigo","vid":"groupInfo.codigo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.data.codigo,"outlined":"","dense":"","error-messages":errors[0]},model:{value:(_vm.groupInfo.codigo),callback:function ($$v) {_vm.$set(_vm.groupInfo, "codigo", $$v)},expression:"groupInfo.codigo"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label-form',{attrs:{"text":"Descripción","required":""}}),_c('validation-provider',{attrs:{"name":"Descripcion","vid":"groupInfo.descripcion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"value":_vm.data.descripcion,"outlined":"","dense":"","rows":"2","error-messages":errors[0]},model:{value:(_vm.groupInfo.descripcion),callback:function ($$v) {_vm.$set(_vm.groupInfo, "descripcion", $$v)},expression:"groupInfo.descripcion"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-2",attrs:{"cols":"12"}},[_c('label-form',{attrs:{"text":"Buscar Clientes"}}),_c('validation-provider',{attrs:{"name":"clientes","vid":"selectCli","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.clients,"disabled":_vm.loadClients,"dense":"","outlined":"","no-data-text":"No hay registros que coincidan","placeholder":"","item-text":"cli_des","return-object":"","persistent-hint":"","hint":"Seleccione para agregar a la lista del grupo.","prepend-inner-icon":"mdi-magnify","error-messages":errors.length > 0 ? 'Debe agregar al menos un cliente al grupo.' : ''},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.clients.length > 0)?_c('v-icon',{attrs:{"color":"blue-grey lighten-3","size":"22"}},[_vm._v("mdi-chevron-down")]):_vm._e(),(_vm.loadClients)?_c('v-progress-circular',{attrs:{"indeterminate":"","width":3,"size":"20","color":"primary"}}):_vm._e()]},proxy:true},{key:"selection",fn:function(){return undefined},proxy:true},{key:"item",fn:function(data){return [_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-avatar',{attrs:{"color":"icono","size":"30"}},[_c('span',{staticClass:"font-weight-bold white--text"},[_vm._v(" "+_vm._s(_vm._f("fisrtLetter")(data.item.cli_des))+" ")])])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold blue-grey--text",domProps:{"innerHTML":_vm._s(data.item.cli_des)}}),_c('v-list-item-subtitle',{staticClass:"muted-text font-italic",domProps:{"innerHTML":_vm._s(data.item.telefonos)}})],1)]}}],null,true),model:{value:(_vm.selectCli),callback:function ($$v) {_vm.selectCli=$$v},expression:"selectCli"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-4",attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v("Clientes")]),_c('v-data-iterator',{staticClass:"list-clients",attrs:{"items":_vm.clientesSelected,"items-per-page":4},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('v-list',{staticClass:"pt-0",attrs:{"dense":""}},[_vm._l((items),function(item,index){return [_c('v-list-item',{key:item.co_cli,staticClass:"px-0 my-1",staticStyle:{"height":"30px"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text font-weight-bold",staticStyle:{"font-size":"0.7125rem !important"},domProps:{"textContent":_vm._s(item.cli_des)}}),_c('v-list-item-subtitle',{staticClass:"text--primary",domProps:{"textContent":_vm._s(item.telefonos)}})],1),_c('v-list-item-action',[_c('btn-delete',{on:{"confirmed":function($event){return _vm.deleteClient(index)}}})],1)],1),(index < items.length - 1)?_c('v-divider'):_vm._e()]})],2)]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mb-0 mt-4",attrs:{"cols":"12"}},[_c('v-list',{staticClass:"pa-0 ma-0",attrs:{"three-line":""}},[_c('v-list-item',{staticClass:"pa-0 ma-0"},[_c('v-list-item-action',{},[_c('v-switch',{attrs:{"color":"secondary","hide-details":""},model:{value:(_vm.groupInfo.status),callback:function ($$v) {_vm.$set(_vm.groupInfo, "status", $$v)},expression:"groupInfo.status"}})],1),_c('v-list-item-content',{staticClass:"align-self-start"},[_c('v-list-item-title',{staticClass:"display font-weight-bold"},[_vm._v("Habilitar Grupo")]),_c('v-list-item-subtitle',{staticClass:"mt-2"},[_vm._v("Opción para habilitar o deshabilitar un Grupo.")])],1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end mb-3"},[_c('v-btn',{staticClass:"px-8 mx-2",attrs:{"tile":"","depressed":"","color":"blue-grey lighten-5","small":_vm.$vuetify.breakpoint.mobile},on:{"click":function($event){return _vm.cerrar()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"px-8",attrs:{"tile":"","depressed":"","color":"primary","small":_vm.$vuetify.breakpoint.mobile},on:{"click":_vm.actionGroup}},[_vm._v(" "+_vm._s(_vm.action === 'editar' ? 'Actualizar' : 'Crear')+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }