<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="610px"
    scrollable
    content-class="content-scroll"
  >
    <v-card tile>
      <v-card-title class="ma-0 d-flex justify-space-between">
        <span
          class="font-weight-bold primary--text"
          :class="{'text-h4': $vuetify.breakpoint.smAndUp, 'text-h5': $vuetify.breakpoint.xsOnly,}"
        >
          Gestión de Grupo
        </span>
        <v-btn icon plain @click="cerrar()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-overlay
        :value="loadingAction || loading"
        color="white"
        absolute
        opacity=".8"
      >
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
      <v-card-text>
        <validation-observer ref="GROUP_FORM" tag="div">
          <v-row>
            <v-col cols="12" sm="7" md="8" lg="8" class="pt-2 pb-0">
              <label-form text="Nombre" required />
              <validation-provider name="Nombre" vid="groupInfo.nombre" rules="required" v-slot="{ errors }">
                <v-text-field
                  :value="data.nombre"
                  v-model="groupInfo.nombre"
                  outlined
                  dense
                  :error-messages="errors[0]"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="5" md="4" lg="4" class="pt-2 pb-0">
              <label-form text="Código" required/>
              <validation-provider name="codigo" vid="groupInfo.codigo" rules="required" v-slot="{ errors }">
                <v-text-field
                  :value="data.codigo"
                  v-model="groupInfo.codigo"
                  outlined
                  dense
                  :error-messages="errors[0]"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <label-form text="Descripción" required/>
              <validation-provider name="Descripcion" vid="groupInfo.descripcion" rules="required" v-slot="{ errors }">
                <v-textarea
                  :value="data.descripcion"
                  v-model="groupInfo.descripcion"
                  outlined
                  dense
                  rows="2"
                  :error-messages="errors[0]"
                />
               </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-2">
              <label-form text="Buscar Clientes" />
              <validation-provider name="clientes" vid="selectCli" rules="required" v-slot="{ errors }">
                <v-autocomplete
                  v-model="selectCli"
                  :items="clients"
                  :disabled="loadClients"
                  dense
                  outlined
                  no-data-text="No hay registros que coincidan"
                  placeholder=""
                  item-text="cli_des"
                  return-object
                  persistent-hint
                  hint="Seleccione para agregar a la lista del grupo."
                  prepend-inner-icon="mdi-magnify"
                  :error-messages="errors.length > 0 ? 'Debe agregar al menos un cliente al grupo.' : ''"
                >
                  <template v-slot:append>
                    <v-icon v-if="clients.length > 0" color="blue-grey lighten-3" size="22">mdi-chevron-down</v-icon>
                    <v-progress-circular
                      v-if="loadClients"
                      indeterminate
                      :width="3"
                      size="20"
                      color="primary"
                    />
                  </template>
                  <template v-slot:selection=""></template>
                  <template v-slot:item="data">
                    <v-list-item-icon class="mr-3">
                      <v-avatar
                        color="icono"
                        size="30"
                      >
                        <span class="font-weight-bold white--text">
                          {{ data.item.cli_des | fisrtLetter }}
                        </span>
                      </v-avatar>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        class="font-weight-bold blue-grey--text"
                        v-html="data.item.cli_des"
                      />
                      <v-list-item-subtitle
                        class="muted-text font-italic"
                        v-html="data.item.telefonos"
                      />
                    </v-list-item-content>
                </template>
                </v-autocomplete>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>

            <v-col cols="12" class="pt-4">
              <span class="font-weight-bold primary--text">Clientes</span>
              <v-data-iterator
                :items="clientesSelected"
                :items-per-page="4"
                class="list-clients"
              >
                <template v-slot:default="{ items }">
                  <v-list dense class="pt-0">
                    <template v-for="(item, index) in items">
                      <v-list-item
                        style="height:30px"
                        class="px-0 my-1"
                        :key="item.co_cli"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            class="primary--text font-weight-bold"
                            style="font-size: 0.7125rem !important;"
                            v-text="item.cli_des" />

                          <v-list-item-subtitle
                            class="text--primary"
                            v-text="item.telefonos"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <btn-delete @confirmed="deleteClient(index)" />
                          <!-- <v-btn
                            icon
                            class=""
                            @click=""
                          >
                            <v-icon
                              size="20"
                              color="blue-grey"
                              v-text="'mdi-trash-can-outline'"
                            />
                          </v-btn> -->
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider v-if="index < items.length - 1" />
                    </template>
                  </v-list>
                </template>
              </v-data-iterator>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="mb-0 mt-4">
              <v-list
                three-line
                class="pa-0 ma-0"
              >
                <v-list-item class="pa-0 ma-0">
                  <v-list-item-action class="">
                    <v-switch
                      v-model="groupInfo.status"
                      color="secondary"
                      hide-details
                    ></v-switch>
                  </v-list-item-action>
                  <v-list-item-content class="align-self-start">
                    <v-list-item-title class="display font-weight-bold">Habilitar Grupo</v-list-item-title>
                    <v-list-item-subtitle class="mt-2">Opción para habilitar o deshabilitar un Grupo.</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions class="d-flex justify-end mb-3">
        <v-btn
          tile
          depressed
          color="blue-grey lighten-5"
          class="px-8 mx-2"
          :small="$vuetify.breakpoint.mobile"
          @click="cerrar()"
        >
          Cancelar
        </v-btn>
        <v-btn
          tile
          depressed
          color="primary"
          class="px-8"
          :small="$vuetify.breakpoint.mobile"
          @click="actionGroup"
        >
          {{action === 'editar' ? 'Actualizar' : 'Crear'}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { getClients, saveGroup } from '../../../../services/mensajeria'
const dataDefault = () => ({
  nombre: '',
  codigo: '',
  descripcion: '',
  clientes:[],
  status: true,
});
export default {
  name:'ModalBanco',
  components: {
    BtnDelete: () => import(
      /* webpackChunkName: 'btn-delete' */
      './BtnDelete.vue'
    ),
  },
  props:{
    value: Boolean,
    data:{
      type: Object,
      default: () => ({}),
    },
    loading:{
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: 'crear',
    },
  },
  data() {
    return {
      show: this.value,
      loadingAction: false,
      loadClients: false,
      groupInfo: dataDefault(),
      clients: [],
      clientesSelected:[],
      selectCli: null
    }
  },
  watch: {
    show (val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value (val, oldVal) {
      if (val === oldVal) return

      this.show = val
    },
    data(val) {
      if(Object.values(val).length > 0) {
        this.groupInfo = {...val, status: Boolean(val.status) }
      }
      else
        this.groupInfo = dataDefault();
    },
    selectCli (val, oldVal) {
      this.clientesSelected.push({ ...val })
    },
  },
  filters: {
    fisrtLetter(val) {
      return val !== null ? val.toUpperCase().charAt(0) : ''
    }
  },
  created () {
    this.getClientes()
  },
  methods: {
    cerrar() {
      this.show = false;
      this.groupInfo = dataDefault();
      this.$refs.GROUP_FORM.reset();
    },
    async getClientes() {
      this.loadClients = true
      try {
        this.clients = await getClients()
      } catch (error) {
        console.error(error)
      } finally {
        this.loadClients = false
      }
    },
    deleteClient(index) {
      let { clientes } = this.groupInfo
      clientes.splice(index, 1)
    },
    async actionGroup() {
      const valid = await this.$refs.GROUP_FORM.validate();
      if(valid) {
        try {
          this.loadingAction = true;
          const { message } = await saveGroup({
            info: this.groupInfo,
            method: this.action === 'crear' ? 'post' : 'put',
            idGroup: this.groupInfo?.id
          })
          this.$emit('procesado', true);
          this.cerrar();
          this.$root.$showAlert(message, 'success');
        } catch (error) {
            this.$root.$showAlert(
              'Lo sentimos, hubo un error al intentar realizar esta acción en el Servidor.',
              'error'
            );
        } finally {
          this.loadingAction = false;
        }
      }
    },
  },
}
</script>
<style>
.list-clients .v-data-footer__select {
  display: none;
}
</style>
